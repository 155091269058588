import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import { change } from "../redux/lnaguageSlice"; // Ensure the correct path

const UseGeneral = () => {
  // Select language from Redux store with fallback to 'en'
  const language = useSelector((state) => state?.language?.language || "en");
  const [siteData, setSiteData] = useState(null);

  // Retrieve any additional site data (like logos, etc.)
  const globalSiteData = useSelector((state) => state?.site?.siteData);
  const dispatch = useDispatch();

  // Function to change the language in the Redux store
  const changeLanguage = useCallback(
    (newLanguage) => {
      if (newLanguage) {
        dispatch(change(newLanguage));
      }
    },
    [dispatch]
  );

  // Effect to update body classes based on language
  useEffect(() => {
    if (language === "ar") {
      document.body.classList.add("arVersion");
      document.body.classList.remove("enVersion");
      document.body.setAttribute("dir", "rtl");
    } else {
      document.body.classList.add("enVersion");
      document.body.classList.remove("arVersion");
      document.body.setAttribute("dir", "ltr");
    }
  }, [language]);

  // Effect to manage site data if globalSiteData changes
  useEffect(() => {
    if (globalSiteData && globalSiteData.logo) {
      setSiteData(globalSiteData); // Only set if new data exists
    }
  }, [globalSiteData]);

  return {
    language: language.toLowerCase(),
    changeLanguage,
    siteData,
  };
};

export default UseGeneral;
