import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Header.css";
import { ChevronLeft, upLoadIcon } from "../../assets/svgIcons/svgIcons";
import SearchInput from "./../../components/SearchInput/SearchInput";
import axios from "axios";
import { BASEURL } from "../../API/BaseUrl";
import UseGeneral from "../../hooks/useGeneral";
import ProdSearchCard from "../../components/prodSearchCard/ProdSearchCard";
import logo from "../../assets/logo.svg";

const Header = ({ onOpenDrawer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState(""); // Search input value
  const [focusSearch, setFocusSearch] = useState(false); // Manage focus state for search
  const [prodLoading, setProdLoading] = useState(false); // Show loading state
  const [productsData, setProductsData] = useState([]); // Store search results
  const { language } = UseGeneral(); // Fetch language context
  const inputRef = useRef(null);

  // API call to search products by query (name, summary, or product code)
  const fetchSearchResults = async (searchQuery) => {
    setProdLoading(true); // Show loading spinner during API call
    try {
      // Make sure the endpoint is correct and matches the API
      const res = await axios.post(
        `${BASEURL}products/search`, // Corrected endpoint URL
        { search: searchQuery }, // Send search query in the body
        {
          headers: {
            "Accept-Language": language, // Set Accept-Language header
          },
        }
      );

      // Check if data is returned correctly
      if (res.data && Array.isArray(res.data.data)) {
        setProductsData(res.data.data); // Update state with API response data
      } else {
        setProductsData([]); // Clear productsData if no products are found
      }
    } catch (error) {
      console.error("Error fetching search results:", error); // Handle errors properly
      setProductsData([]); // Clear productsData on error
    } finally {
      setProdLoading(false); // Hide loading spinner after API call
    }
  };

  // Handle changes in search input to dynamically fetch search results
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchValue(query); // Update search input state

    if (query.length > 0) {
      fetchSearchResults(query); // Call search API with the new query
      setFocusSearch(true); // Open the search modal
    } else {
      setProductsData([]); // Clear productsData if input is cleared
      setFocusSearch(false); // Close the search modal
    }
  };

  // Close the search bar when clicking the back button
  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
    setSearchValue(""); // Clear the search input
    setFocusSearch(false); // Close the search input box
  };

  // Close modal if clicked outside search results
  const handleCloseSearch = (e) => {
    if (e.target.classList.contains("search_overlay")) {
      setFocusSearch(false); // Close modal
    }
  };

  useEffect(() => {
    setFocusSearch(false); // Reset search focus when location changes
  }, [location]);

  return (
    <div className="header_container">
      <div className="top_header">
        <div
          className={`header_icon ${language === "ar" ? "" : "english"}`}
          onClick={handleBackClick}
        >
          {ChevronLeft}
        </div>
        <div className="page_name">
          <img src={logo} alt="logo" />
        </div>
        <div className="header_icon mt-4s" onClick={() => onOpenDrawer(true)}>
          {upLoadIcon}
        </div>
      </div>

      <div className="searchHeader mt-3">
        {!["settings", "productDetails", "cart"].find((item) =>
          location.pathname.includes(item)
        ) && (
          <>
            <SearchInput
              reference={inputRef}
              name="search"
              value={searchValue} // Bind search input value
              onFocus={() => setFocusSearch(true)} // Handle focus on search input
              isFocust={focusSearch}
              onCloseClick={() => {
                setSearchValue(""); // Clear search value on close
                setFocusSearch(false);
              }}
              onChange={handleSearchChange} // Call handleSearchChange when input changes
              placeholder={
                language === "ar" ? "ابحث عن المنتجات" : "Search for a product "
              }
            />
          </>
        )}
      </div>

      {/* Modal overlay for search results */}
      {focusSearch && (
        <div className="search_overlay" onClick={handleCloseSearch}>
          <div className="search_popup">
            {prodLoading ? (
              <div className="d-flex align-items-center justify-content-center w-100">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : productsData && productsData.length >= 1 ? (
              <div className="search_products_items">
                {productsData.map((item) => (
                  <div className="search_prod_card" key={item.id}>
                    <ProdSearchCard
                      setFocusSearch={setFocusSearch}
                      data={item}
                    />
                  </div>
                ))}
              </div>
            ) : (
              !prodLoading && (
                <div className="no-results">
                  {language === "ar"
                    ? "لم يتم العثور على منتجات"
                    : "No products found"}
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
