import React, { useEffect, useState } from "react";
import "./ProdSearchCard.css"; // Assuming you have styling for ProdSearchCard
import { kspPlus, kspMinus } from "../../assets/svgIcons/svgIcons"; // Assuming you have the plus/minus icons
import UseGeneral from "../../hooks/useGeneral";
import useUserId from "../../hooks/useUserId";
import axios from "axios";
import { BASEURL } from "../../API/BaseUrl";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getRender } from "../../redux/rerednerSlice";

const ProdSearchCard = ({ data, setFocusSearch }) => {
  const [loading, setLoading] = useState(false);
  const [itemCart, setItemCart] = useState(null); // Manage item count in the cart
  const { userId } = useUserId();
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Handle adding or removing items from the cart
  const handleChangeCart = async (action) => {
    setLoading(true);

    const count =
      action === "add"
        ? itemCart
          ? +itemCart.count + 1
          : 1
        : +itemCart.count - 1;

    const dataSet = {
      product_id: data.id,
      user_id: userId,
      count,
    };

    try {
      const res = await axios.post(
        `${BASEURL}products/search`,
        JSON.stringify(dataSet)
      );
      if (res.data.status === "success") {
        toast.success(
          action === "add"
            ? language === "ar"
              ? "تمت الإضافة الى السلة بنجاح"
              : "Added to cart"
            : language === "ar"
            ? "تم الإزالة من السلة بنجاح"
            : "Removed from cart"
        );
        dispatch(getRender()); // Trigger re-render in redux
      } else {
        toast.error(language === "ar" ? "هناك مشكلة ما" : "An issue occurred");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Sanitize image URLs safely, check if data.caption is valid
  const sanitizeImageUrl = (url) => {
    return url
      ? url.replace(
          "uploaded-file/images/products/uploaded-file/",
          "uploaded-file/"
        )
      : "/path/to/default-image.jpg"; // Fallback to default image
  };

  // Ensure data.prices is valid and parse it, or use default values if not
  const minPrice = data.prices
    ? Math.min(
        ...JSON.parse(data.prices.replace(/'/g, '"')).map((item) => +item[1])
      )
    : 0;

  const maxPrice = data.prices
    ? Math.max(
        ...JSON.parse(data.prices.replace(/'/g, '"')).map((item) => +item[1])
      )
    : 0;

  return (
    <div
      className="prod_search_card"
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/product-details/${data.id}`);
        setFocusSearch(false); // Close search dropdown on click
      }}
    >
      <div className="prod_search_card_image">
        <img
          src={sanitizeImageUrl(data.caption)}
          alt={data.name || "Product"}
        />
      </div>
      <div className="prod_search_card_content">
        <h3 className="psc_title">{data.name}</h3>
      </div>

      {/* Add to cart button */}
      {/* <div
        className="add_icon"
        onClick={(e) => {
          e.stopPropagation(); // Prevent triggering navigation
          handleChangeCart("add"); // Add to cart
        }}
      >
        {kspPlus}
      </div> */}

      {/* Show remove button and count if item is in the cart */}
      {/* {itemCart && itemCart.count >= 1 && (
        <>
          <div
            className="remove_from_cart_button_search"
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering navigation
              handleChangeCart("minus"); // Remove from cart
            }}
          >
            {kspMinus}
          </div>
          <div className="cart_count_padge right">{itemCart.count}</div>
        </>
      )} */}
    </div>
  );
};

export default ProdSearchCard;
