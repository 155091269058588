// import React, { createContext, useState, useEffect } from "react";

// export const CartContext = createContext();

// export const CartProvider = ({ children }) => {
//   // Load initial cart data from localStorage if it exists
//   const [cartItems, setCartItems] = useState(() => {
//     const savedCart = localStorage.getItem("cartItems");
//     return savedCart ? JSON.parse(savedCart) : [];
//   });

//   // State to manage quantities of products across both pages
//   const [quantities, setQuantities] = useState(() => {
//     const savedQuantities = localStorage.getItem("cartQuantities");
//     return savedQuantities ? JSON.parse(savedQuantities) : {};
//   });

//   // Save cart data and quantities to localStorage whenever they change
//   useEffect(() => {
//     localStorage.setItem("cartItems", JSON.stringify(cartItems));
//     localStorage.setItem("cartQuantities", JSON.stringify(quantities));
//   }, [cartItems, quantities]);

//   // Add product to cart and update quantity
//   const addToCart = (product) => {
//     setCartItems((prevItems) => {
//       const existingProduct = prevItems.find(
//         (item) => item.product_id === product.product_id
//       );

//       if (existingProduct) {
//         // If the product already exists in the cart, increase its quantity
//         return prevItems.map((item) =>
//           item.product_id === product.product_id
//             ? { ...item, quantity: item.quantity + 1 }
//             : item
//         );
//       } else {
//         // Otherwise, add the new product with a quantity of 1
//         return [...prevItems, { ...product, quantity: 1 }];
//       }
//     });

//     // Update quantities state
//     setQuantities((prevQuantities) => ({
//       ...prevQuantities,
//       [product.product_id]: (prevQuantities[product.product_id] || 0) + 1,
//     }));
//   };

//   // Remove product from cart and reset quantity in quantities state
//   const removeFromCart = (product_id) => {
//     setCartItems((prevItems) =>
//       prevItems.filter((item) => item.product_id !== product_id)
//     );

//     // Reset quantity to 0 in the quantities state
//     setQuantities((prevQuantities) => ({
//       ...prevQuantities,
//       [product_id]: 0,
//     }));
//   };

//   // Update product quantity in the cart (used for both increment and decrement)
//   const updateQuantity = (product_id, quantity) => {
//     setCartItems(
//       (prevItems) =>
//         prevItems
//           .map((item) =>
//             item.product_id === product_id ? { ...item, quantity } : item
//           )
//           .filter((item) => item.quantity > 0) // Remove items with quantity <= 0
//     );

//     // Update quantities state globally
//     setQuantities((prevQuantities) => ({
//       ...prevQuantities,
//       [product_id]: quantity,
//     }));
//   };

//   // Get total price of all items in the cart
//   const getTotalPrice = () => {
//     return cartItems.reduce(
//       (total, item) => total + item.prices[0].price * item.quantity,
//       0
//     );
//   };

//   return (
//     <CartContext.Provider
//       value={{
//         cartItems,
//         quantities,
//         addToCart,
//         removeFromCart,
//         updateQuantity,
//         getTotalPrice,
//       }}
//     >
//       {children}
//     </CartContext.Provider>
//   );
// };

import React, { createContext, useState, useEffect } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  // Load initial cart data from localStorage if it exists
  const [cartItems, setCartItems] = useState(() => {
    const savedCart = localStorage.getItem("cartItems");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // State to manage quantities of products
  const [quantities, setQuantities] = useState(() => {
    const savedQuantities = localStorage.getItem("cartQuantities");
    return savedQuantities ? JSON.parse(savedQuantities) : {};
  });

  // Save cart data and quantities to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    localStorage.setItem("cartQuantities", JSON.stringify(quantities));
  }, [cartItems, quantities]);

  // Add product to cart and update quantity
  const addToCart = (product) => {
    setCartItems((prevItems) => {
      const existingProduct = prevItems.find(
        (item) => item.product_id === product.product_id
      );

      if (existingProduct) {
        // If the product already exists in the cart, increase its quantity
        return prevItems.map((item) =>
          item.product_id === product.product_id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        // Otherwise, add the new product with a quantity of 1 and include market prices
        return [...prevItems, { ...product, quantity: 1 }];
      }
    });

    // Update quantities state
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [product.product_id]: (prevQuantities[product.product_id] || 0) + 1,
    }));
  };

  // Update product quantity in the cart (used for both increment and decrement)
  const updateQuantity = (product_id, quantity) => {
    if (quantity <= 0) {
      // Remove item if quantity is 0 or below
      removeFromCart(product_id);
    } else {
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.product_id === product_id ? { ...item, quantity } : item
        )
      );

      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [product_id]: quantity,
      }));
    }
  };

  // Remove product from cart and reset quantity in quantities state
  const removeFromCart = (product_id) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.product_id !== product_id)
    );

    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      delete newQuantities[product_id];
      return newQuantities;
    });
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        quantities,
        addToCart,
        removeFromCart,
        updateQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
