import { useEffect, useState } from "react";
import styles from "./ChooseCountry.module.css";
import "./ChooseCountry.css";
import axios from "axios";
import { BASEURL } from "../../API/BaseUrl";
import toast from "react-hot-toast";
import UseGeneral from "../../hooks/useGeneral"; // Import UseGeneral to get global language
import { useNavigate } from "react-router-dom";

export default function ChooseCountry({ getSelectedCity }) {
  const [selectedCity, setSelectedCity] = useState("");
  const { language, location } = UseGeneral(); // Get global language and location from custom hook
  const [cities, setCities] = useState([]); // Store cities retrieved from API
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch cities from the /cities endpoint with Accept-Language header
  const getAllCities = async () => {
    setLoading(true);

    await axios
      .get(`${BASEURL}cities`, {
        headers: {
          "Accept-Language": language, // Send Accept-Language header based on global language
        },
      })
      .then((res) => {
        if (res.data && res.data.data) {
          setCities(res.data.data); // Store cities data in state
        } else {
          toast.error(
            language === "ar"
              ? "هناك مشكلة في جلب المدن"
              : "There was a problem fetching cities"
          );
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(
          language === "ar" ? "خطأ في جلب البيانات" : "Error fetching data"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Retrieve the selected city from localStorage
  const getStoredCity = () => {
    const storedCity = JSON.parse(localStorage.getItem("ksbCity")) || "";
    setSelectedCity(storedCity);
  };

  // Save selected city to localStorage and navigate to markets
  const onSaveCity = () => {
    if (!selectedCity) {
      toast.error(
        language === "ar" ? "قم باختيار مدينة" : "Please select a city"
      );
      return;
    }

    localStorage.setItem("ksbCity", JSON.stringify(selectedCity)); // Save the selected city to localStorage
    navigate("/settings/markets"); // Navigate to markets after city selection
  };

  // Fetch cities and stored city on component load
  useEffect(() => {
    getAllCities();
    getStoredCity();
  }, [language]); // Refetch cities when the language changes

  // Debugging for location
  useEffect(() => {
    if (location) console.log(location);
  }, [location]);

  return (
    <>
      <div className={styles.countriesContainer}>
        {loading ? (
          <div className="loading-message">
            {language === "ar" ? "جارٍ تحميل المدن..." : "Loading cities..."}
          </div>
        ) : (
          cities.map((c, i) => (
            <div
              className={`country ${
                selectedCity.city_id === c.city_id ? " active " : ""
              }`}
              onClick={() => setSelectedCity(c)}
              key={c.city_id} // Using city_id as key for uniqueness
            >
              <p>{language === "ar" ? c.name_ar : c.name_en}</p>
            </div>
          ))
        )}
        <div className="save-button" onClick={() => onSaveCity()}>
          {language === "ar" ? "حفظ" : "Save"}
        </div>
      </div>
    </>
  );
}
