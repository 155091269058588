import {
  SettingsCog,
  Shopping,
  favIcon,
  fork_spoonIcon,
  kspCard,
  kspCard2,
  kspHome,
  kspMoreIcon,
  kspOffers,
  search,
} from "../../assets/svgIcons/svgIcons";
import "./Footer.css";
import { useLocation, useNavigate } from "react-router-dom";
import UseGeneral from "../../hooks/useGeneral";
import { useContext } from "react";
import { CartContext } from "../../Context/CartContext"; // Import CartContext

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = UseGeneral();
  const { cartItems } = useContext(CartContext); // Access cartItems from CartContext

  // Calculate total quantity of items in the cart
  const totalCartQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const footerItems = [
    {
      id: 1,
      name: language === "ar" ? "الرئيسية" : "Home",
      icon: kspHome,
      pathname: "/",
    },
    {
      id: 2,
      name: language === "ar" ? "السلة" : "Cart",
      icon: kspCard,
      pathname: "/cart",
    },
    {
      id: 3,
      name: language === "ar" ? "العروض" : "Offers",
      icon: kspOffers,
      pathname: "/offers",
    },
    {
      id: 4,
      name: language === "ar" ? "المزيد" : "More",
      icon: kspMoreIcon,
      pathname: "/settings",
    },
  ];

  return (
    <div className="footer_container">
      {footerItems?.map((item) => {
        return (
          <div
            className={`footer_item ${
              location.pathname === item.pathname ? "active" : ""
            }`}
            key={item.id}
            onClick={() => navigate(item.pathname)}
          >
            <div className={`footer_icon`}>{item.icon}</div>
            <div className="footer_item_text">{item.name}</div>
            {item.pathname === "/cart" && (
              <div className="footer_padge">
                {totalCartQuantity} {/* Display total quantity in cart */}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Footer;
