// import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { Provider } from "react-redux"; // Import Provider from react-redux
// import { store } from "./redux/store"; // Import your Redux store
// import Categories from "./components/homeComponents/Categories/Categories"; // Categories component
// // import Layout from "./components/Layout/Layout";
// import DefaultLayout from "./components/layouts/defaultLayout";
// import Products from "./pages/Products/Products"; // Products component
// import Settings from "./pages/Settings/Settings";
// import { Toaster } from "react-hot-toast"; // Import the Toaster for toast notifications
// import Markets from "./pages/Markets/Markets";
// import ChooseCountry from "./pages/ChooseCountry/ChooseCountry";
// import ThemesPage from "./pages/ThemesPage/ThemesPage";
// import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
// import ContactUs from "./pages/ContactUs/ContactUs";
// import ChooseLanguage from "./pages/ChooseLanguage/ChooseLanguage";
// import CartPage from "./pages/Cart/Cart";

// function App() {
//   return (
//     <Provider store={store}>
//       {" "}
//       {/* Wrap the app in Redux Provider */}
//       <Router>
//         {/* Layout wrapping all routes */}
//         <DefaultLayout>
//           {/* Add Toaster for global notifications */}
//           <Toaster position="top-center" reverseOrder={false} />

//           <Routes>
//             {/* Categories page at the root */}
//             <Route path="/" element={<Categories />} />
//             {/* Products page: Takes category_id as a parameter */}
//             <Route path="/products/:category_id" element={<Products />} />
//             <Route path="/settings/" element={<Settings />} />
//             <Route path="settings/markets/" element={<Markets />} />
//             <Route path="settings/ChooseCountry/" element={<ChooseCountry />} />
//             <Route path="settings/themes/" element={<ThemesPage />} />
//             <Route path="settings/privacypolicy/" element={<PrivacyPolicy />} />
//             <Route path="settings/ContactUs/" element={<ContactUs />} />
//             <Route
//               path="settings/ChooseLanguage/"
//               element={<ChooseLanguage />}
//             />
//             <Route path="Cart/" element={<CartPage />} />
//           </Routes>
//         </DefaultLayout>
//       </Router>
//     </Provider>
//   );
// }

// export default App;

// import React, { lazy, Suspense, useEffect } from "react";
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   useLocation,
//   useNavigate,
// } from "react-router-dom";
// import { Provider, useSelector } from "react-redux"; // Using useSelector to get userId from Redux
// import { store } from "./redux/store";
// import DefaultLayout from "./components/layouts/defaultLayout";
// import { Toaster } from "react-hot-toast";

// // Lazy load components
// const Categories = lazy(() =>
//   import("./components/homeComponents/Categories/Categories")
// );
// const Products = lazy(() => import("./pages/Products/Products"));
// const Settings = lazy(() => import("./pages/Settings/Settings"));
// const Markets = lazy(() => import("./pages/Markets/Markets"));
// const ChooseCountry = lazy(() => import("./pages/ChooseCountry/ChooseCountry"));
// const ThemesPage = lazy(() => import("./pages/ThemesPage/ThemesPage"));
// const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
// const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"));
// const ChooseLanguage = lazy(() =>
//   import("./pages/ChooseLanguage/ChooseLanguage")
// );
// const CartPage = lazy(() => import("./pages/Cart/Cart"));
// const ProductDetails = lazy(() =>
//   import("./pages/ProductDetails/ProductDetails")
// );

// // This component will be responsible for adding the userId to the URL
// const UpdateUrlWithUserId = () => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   // Assume userId is stored in the Redux store (or any other global state)
//   const userId = useSelector((state) => state.user.userId); // Adjust this according to your store structure

//   useEffect(() => {
//     if (!userId) return; // Only update if userId exists

//     const newUrl = new URL(window.location.href);
//     newUrl.searchParams.set("userId", userId); // Dynamically set the userId from store or context

//     // Update the browser's URL without reloading the page
//     if (window.history) {
//       window.history.replaceState(null, "", newUrl.toString());
//     }

//     // Example of a side effect after updating the URL
//     setTimeout(() => {
//       console.log(`URL updated with userId=${userId}`);
//     }, 100);
//   }, [location, userId, navigate]);

//   return null; // No UI needed for this component
// };

// function App() {
//   return (
//     <Provider store={store}>
//       <Router>
//         <UpdateUrlWithUserId />{" "}
//         {/* This component will run only inside the Router */}
//         <DefaultLayout>
//           <Toaster position="top-center" reverseOrder={false} />
//           <Suspense fallback={<div>Loading...</div>}>
//             <Routes>
//               <Route path="/" element={<Categories />} />
//               <Route path="/products/:category_id" element={<Products />} />
//               <Route path="/settings/" element={<Settings />} />
//               <Route path="/settings/markets/" element={<Markets />} />
//               <Route
//                 path="/settings/ChooseCountry/"
//                 element={<ChooseCountry />}
//               />
//               <Route path="/settings/themes/" element={<ThemesPage />} />
//               <Route
//                 path="/settings/privacypolicy/"
//                 element={<PrivacyPolicy />}
//               />
//               <Route path="/settings/ContactUs/" element={<ContactUs />} />
//               <Route
//                 path="/settings/ChooseLanguage/"
//                 element={<ChooseLanguage />}
//               />
//               <Route path="/cart" element={<CartPage />} />
//               <Route
//                 path="/product-details/:product_id"
//                 element={<ProductDetails />}
//               />
//             </Routes>
//           </Suspense>
//         </DefaultLayout>
//       </Router>
//     </Provider>
//   );
// }

// export default App;

import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Provider } from "react-redux"; // Using useSelector to get userId from Redux
import { store } from "./redux/store";
import DefaultLayout from "./components/layouts/defaultLayout";
import { Toaster } from "react-hot-toast";
// import UseSelectLocation from "./hooks/UseSelectLocation";

// Lazy load components
const Categories = lazy(() =>
  import("./components/homeComponents/Categories/Categories")
);
const Products = lazy(() => import("./pages/Products/Products"));
const Settings = lazy(() => import("./pages/Settings/Settings"));
const Markets = lazy(() => import("./pages/Markets/Markets"));
const ChooseCountry = lazy(() => import("./pages/ChooseCountry/ChooseCountry"));
const ThemesPage = lazy(() => import("./pages/ThemesPage/ThemesPage"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"));
const ChooseLanguage = lazy(() =>
  import("./pages/ChooseLanguage/ChooseLanguage")
);
const CartPage = lazy(() => import("./pages/Cart/Cart"));
const ProductDetails = lazy(() =>
  import("./pages/ProductDetails/ProductDetails")
);

const UpdateUrlWithUserId = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Retrieve user_id from URL or localStorage
    const urlUserId = searchParams.get("user_id");
    const storedUserId = localStorage.getItem("user_id");

    if (urlUserId) {
      // If URL has a user_id, update localStorage
      localStorage.setItem("user_id", urlUserId);
    } else if (storedUserId) {
      // If no user_id in URL but present in localStorage, update URL
      searchParams.set("user_id", storedUserId);
      setSearchParams(searchParams);
    } else {
      // Default case: set a default user_id in URL and localStorage
      const defaultUserId = "";
      searchParams.set("user_id", defaultUserId);
      setSearchParams(searchParams);
      localStorage.setItem("user_id", defaultUserId);
    }
  }, [location, searchParams, setSearchParams]);

  return null; // This component doesn't render anything
};

function App() {
  return (
    <Provider store={store}>
      <Router>
        <UpdateUrlWithUserId />{" "}
        {/* This component will run only inside the Router */}
        <DefaultLayout>
          <Toaster position="top-center" reverseOrder={false} />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Categories />} />
              <Route path="/products/:category_id" element={<Products />} />
              <Route path="/settings/" element={<Settings />} />
              <Route path="/settings/markets/" element={<Markets />} />
              <Route
                path="/settings/ChooseCountry/"
                element={<ChooseCountry />}
              />
              <Route path="/settings/themes/" element={<ThemesPage />} />
              <Route
                path="/settings/privacypolicy/"
                element={<PrivacyPolicy />}
              />
              <Route path="/settings/ContactUs/" element={<ContactUs />} />
              <Route
                path="/settings/ChooseLanguage/"
                element={<ChooseLanguage />}
              />
              <Route path="/cart" element={<CartPage />} />
              <Route
                path="/product-details/:product_id"
                element={<ProductDetails />}
              />
            </Routes>
          </Suspense>
        </DefaultLayout>
      </Router>
    </Provider>
  );
}

export default App;
